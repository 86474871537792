<template>
  <div
    class="max-w-xs rounded-xl shadow-lg mb-16 sm:w-72 relative"
    :class="{
      'cursor-pointer': $can('edite,partenaire'),
    }"
    @click="handleClick"
  >
    <div
      v-if="$can('delete,partenaire')"
      @click.stop="handleDelete"
      style="height: min-content; width: min-content"
      class="absolute right-2 top-3 flex items-center justify-center p-3 text-white rounded-full cursor-pointer bg-promy-red"
    >
      <i class="fas fa-trash"></i>
    </div>
    <div
      class="!bg-center !bg-no-repeat !bg-contain h-45 w-full"
      :style="imageStyle(partenaire)"
    ></div>
    <div class="px-4 pt-4 pb-10">
      <p
        class="text-xl font-extrabold transition-all duration-200 font-arial text-promy-green-200"
      >
        {{ partenaire.nom }}
      </p>
      <p class="font-arial mb-3 font-bold text-base text-promy-gray-650">
        {{ partenaire.activity_sector.name }}
      </p>
      <p
        style="overflow-wrap: break-word"
        class="text-promy-gray-550 text-base"
        v-html="
          partenaire.description
            ? $options.filters.truncateTerrain(partenaire.description, 150)
            : ''
        "
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['partenaire'],
  methods: {
    imageStyle(partenaire) {
      return {
        'background-image': `url(${partenaire.logo_path})`,
      }
    },
    handleClick() {
      this.$emit('handleClick', this.partenaire)
    },
    handleDelete() {
      this.$emit('handleDelete', this.partenaire.id)
    },
  },
}
</script>
